.subtitle-4 {
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: $black;
}

.mat-mdc-tooltip.tooltip {
  white-space: pre-line;
  font-size: 14px;
  background-color: $whisper;
  border-radius: 10px;
  color: $black;
  padding: 20px;
}
