@import 'global/normalizer';
@import 'common/variables';
@import 'global/data-sections';
@import 'global/data-tiles';
@import 'global/font-definition';
@import 'global/form-elements';
@import 'global/form-sections';
@import 'global/headers';
@import 'global/layout-columns';
@import 'global/mat-table';
@import 'global/material-overrides';
@import 'global/elements';
@import 'global/text';
@import 'global/components';
@import 'src/styles/global/print';

$mdc-checkbox-padding-base-size: calc((var(--mdc-checkbox-state-layer-size) - 18px) / 2);
$mdc-label-padding-base-size: 2px;

html,
body {
  margin: 0;
  height: 100%;
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  font-size: #{$base-font-size}px;
  line-height: $base-line-size;
  background-color: $page-background-color;
}

p {
  margin: 0 0 1ex 0;
}
p,
span,
label {
  .ng-fa-icon {
    display: inline-block;
    width: 1em;
    text-align: center;
    margin-right: 0.2em;
  }
}

.mat-mdc-tooltip.general-tooltip-content {
  white-space: pre-line;
}

ul {
  padding-left: $form-element-indentation;
}

dd {
  margin-left: $form-element-indentation;
}

.uppy-DashboardContent-addMore {
  display: none;
}

.mat-mdc-radio-button .mdc-radio {
  padding: 0 $mdc-label-padding-base-size 0 0 !important;
}

.mdc-checkbox {
  --mdc-checkbox-state-layer-size: 25px;
  --mdc-checkbox-selected-checkmark-color: white;
  padding: $mdc-checkbox-padding-base-size $mdc-checkbox-padding-base-size $mdc-checkbox-padding-base-size $mdc-label-padding-base-size !important;
}

.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
  left: 0 !important;
  width: 25px !important;
}

stx-textarea {
  .mat-mdc-text-field-wrapper {
    height: auto !important;
  }

  .mat-mdc-form-field {
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.125 !important;
  }
}

.mat-mdc-form-field {
  display: inline-block !important;
  position: relative !important;
  text-align: left !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.125 !important;
  font-family: Noto Sans, sans-serif !important;
  letter-spacing: normal !important;
}

.mat-mdc-menu-panel {
  max-width: 100% !important;
}

stx-select,
stx-time-select,
mat-paginator {
  .mat-mdc-select-value {
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.125 !important;
  }

  .mat-mdc-form-field-infix {
    padding: 0.7em 0 !important;
  }
}

.mat-mdc-option {
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.125 !important;
}

.mat-mdc-text-field-wrapper {
  height: 3rem !important;
}

.mat-mdc-form-field-infix {
  padding: 1em 0 !important;
}

mat-paginator {
  .mat-mdc-form-field-infix {
    padding: 0.7em 0 !important;
  }
}

.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
  align-self: center !important;
  padding-bottom: 1rem !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0 !important;
}

stx-search-filter-container,
stx-input-password,
stx-input-control {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

mat-selection-list,
mat-list {
  .mdc-list-item__primary-text {
    font-size: 13px !important;
  }
}

.mat-mdc-tooltip-surface {
  max-height: 95vh !important;
  max-width: 30vw !important;
}
