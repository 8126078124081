$font-path: '../../assets/fonts';

// Light
@font-face {
  font-family: $font-light;
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}/NotoSans-Light.ttf') format('truetype');
}

// Regular
@font-face {
  font-family: $font-regular;
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}/NotoSans-Regular.ttf') format('truetype');
}

// Bold
@font-face {
  font-family: $font-bold;
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}/NotoSans-Bold.ttf') format('truetype');
}

// Medium
@font-face {
  font-family: $font-medium;
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}/NotoSans-Medium.ttf') format('truetype');
}
