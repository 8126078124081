$gapSpaceAmounts: (0, 2, 5, 7, 10, 15, 20, 24, 25, 30, 32, 35, 40, 45, 50, 75, 100);

@each $space in $gapSpaceAmounts {
  .gap-#{$space} {
    gap: #{$space}px;
  }
}

@each $space in $gapSpaceAmounts {
  .gap-#{$space}-grid {
    > * {
      padding: 0 #{$space}px #{$space}px 0;
    }
  }
}

@for $i from 1 through 100 {
  .custom-flex-#{$i} {
    flex: 1 1 $i * 1%;
    box-sizing: border-box;
    max-width: $i * 1%;
  }
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

.flex-row-important {
  display: flex;
  flex-direction: row !important;
  flex-wrap: nowrap;
  gap: 5px;
}

.flex-row-container {
  display: flex;
  flex-direction: row;
}

.flex-column-container {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-inline {
  display: inline-flex;
}

.flex-0-important {
  flex: 0 !important;
}

.display-flex {
  display: flex;
}

.flex {
  flex: 1;
}

.flex-none-important {
  flex: none !important;
}

.flex-nogrow {
  flex: 0 1 auto;
  display: flex;
}

.flex-grow-shrink {
  flex: 1 1 0;
  display: flex;
}

.align-left {
  justify-content: flex-start;
}

.align-center {
  justify-content: center;
}

.align-right {
  justify-content: flex-end;
}

.align-evenly {
  justify-content: space-evenly;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

// change fxLayoutAlign to CSS classes according to https://github.com/angular/flex-layout/wiki/fxLayoutAlign-API
.layout-align-start {
  align-items: flex-start;
  align-content: flex-start;
}

.layout-align-center {
  align-items: center;
  align-content: center;
}

.layout-align-end {
  align-items: flex-end;
  align-content: flex-end;
}

.layout-align-space-around {
  align-content: space-around;
}

.layout-align-space-between {
  align-content: space-between;
}

.layout-align-stretch-column {
  max-width: 100%;
}

.layout-align-stretch-row {
  max-height: 100%;
}

.layout-align-baseline {
  align-items: baseline;
  align-content: stretch;
}

.hide-gt-md {
  @include gt-md {
    display: none;
  }
}

.flex-flow-wrap {
  flex-flow: wrap;
}
