.black-text {
  color: $black;
}

.text-inverted {
  color: $white;
}

.text-dark-gray {
  color: $dark-grey;
}

.color-link {
  color: $dark-blue;
}

.text-error {
  color: $error;
}
