// constants:
$custom-font-family: 'Noto Sans';
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// layout:
$page-side-margin: 15px;
$page-top-margin: 15px;
$page-background-color: #ffffff;
$page-max-width: 1600px;
$sidebar-background-color: #f9f9fb;

$section-margin-top: 0;
$section-margin-bottom: 15px;

// base text:
$base-font-family: $custom-font-family, sans-serif;
$base-font-size: 14;
$base-form-font-size: 0.875rem;
$base-line-size: 1.2em;
$base-font-weight: $font-weight-medium;
$base-text-color: #000000;

// alternative text:
$inactive-text-color: #bcbcbc;

// alternative colors:
$primary-background-color: #33568a;
$primary-text-color: #ffffff;

// data tiles:
$data-tile-header-background-color: $primary-background-color;
$data-tile-header-color: $primary-text-color;
$data-tile-border-color: #e2e2ec;
$data-tile-border-radius: 6px;
$data-tile-content-spacing-side: 0.7em;
$data-tile-content-spacing-top: 0.7em;
$data-tile-content-spacing-bottom: 1ex;
$data-tile-content-spacing-between: 15px;
$data-tile-between-rows-border-color: #efefef;

// data header:
$data-tile-header-content-spacing-side: 0.7em;

// form elements:
$form-element-spacing-horizontal: 0.8em;
$form-element-spacing-vertical: 0.4em;
$form-element-indentation: $form-element-spacing-horizontal * 2;

// when need to change please look at select.component.scss file and comment below .mat-select-trigger
$form-element-select-option-height: 30px;
$form-element-select-option-mobile-height: 45px;

// notices
$notice-blue-color: #2693ff;
$notice-blue-background-color: #f2f9ff;
$notice-green-color: #9cd821;
$notice-green-background-color: #fbfff2;
$notice-yellow-color: #ffdb26;
$notice-yellow-background-color: #fffdf2;
$notice-red-color: #ff2626;
$notice-red-background-color: #fff2f2;
