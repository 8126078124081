@mixin gt-xs {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin gt-md {
  @media (min-width: 960px) {
    @content;
  }
}

@mixin gt-fx-md {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin gt-hd {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin gt-uhd {
  @media (min-width: 1760px) {
    @content;
  }
}

@mixin lt-hd {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin lt-lg {
  @media (max-width: 1279px) {
    @content;
  }
}

@mixin lt-md {
  @media (max-width: 959px) {
    @content;
  }
}

@mixin lt-sm {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin gt-sm {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 600px) and (max-width: 959px) {
    @content;
  }
}

@mixin flex-changing-container($gap: 0, $row: row) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $gap;

  @include lt-sm {
    @if $row == 'row' {
      flex-direction: row;
    } @else {
      flex-direction: column;
    }
  }
}
