.avoid-page-break {
  break-inside: avoid;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  .hide-on-print {
    display: none !important;
  }
}

@media print {
  .mat-mdc-form-field {
    font-size: 30px;
  }

  stx-image {
    margin: 30px;
  }

  stx-feeding-assessment,
  stx-nutritional-status,
  stx-nutrition-patient-care-type,
  stx-nutrition-care-plan,
  stx-nutrition-anemia-management,
  stx-nutrition-current-feeding,
  stx-nutrition-birthweight {
    flex: 1 !important;
  }
  .mat-form-field-wrapper {
    margin-bottom: unset;
    padding-bottom: unset;
  }

  body {
    zoom: 65%;
  }

  mat-icon.tooltip-icon {
    display: none;
  }

  div {
    .mat-checkbox-background {
      display: none;
    }
    .mat-radio-outer-circle {
      border-color: $black;
    }

    .page-section-container {
      padding: 0;
      border: none;
      overflow: auto;
      box-shadow: none;
      background-color: transparent;
    }
  }
  .mat-mdc-radio-button .mat-radio-label .mat-radio-container .mat-radio-inner-circle {
    background-color: unset;
  }

  //disables the resize icon in textfields
  .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
    resize: none;
  }

  //disables red outline in inputs
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: unset;
  }

  h1 {
    margin: 0;
  }

  .not-print {
    display: none !important;
  }

  div.image {
    margin: 60px 0 60px 60px;
  }

  .page-break {
    page-break-after: always;
  }

  .top-print-margin {
    margin-top: 100px;
  }
  mat-card.mat-mdc-card,
  mat-expansion-panel.mat-expansion-panel {
    box-shadow: none !important;
    background-color: transparent;
    border: none;
  }

  .mat-expansion-indicator {
    display: none;
  }

  .stx-form-field {
    .mat-form-field-suffix {
      display: none;
    }
    &-wide {
      div {
        .mat-form-field-suffix {
          display: none;
        }
      }
    }
  }
  .mat-checkbox-disabled .mat-checkbox-label {
    color: $black;
  }
  .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
    border-color: $black;
    opacity: 0.54;
  }

  stx-readonly-field,
  stx-readonly-view-audio,
  stx-readonly-view-video,
  stx-readonly-view-image {
    break-inside: avoid;
    display: block;
  }
}

stx-patient,
stx-surgical-follow-up,
stx-surgical-intervention,
stx-speech-assessment,
stx-speech-treatment,
stx-borescope,
stx-orthognathic-treatment,
stx-presurgical-orthopedics,
stx-mixed-dentition,
stx-nutrition-management,
stx-permanent-dentition,
stx-permanent-dentition-assessment,
stx-permanent-dentition-mid-assessment,
stx-permanent-dentition-treatment,
stx-mixed-dentition-assessment,
stx-mixed-dentition-mid-assessment,
stx-mixed-dentition-treatment,
stx-psio-assessment,
stx-psio-treatment {
  &:not(.readonly-form-mode) {
    @include cleared-edit-form;
  }
}
