@import 'src/styles-new/common/variables';
@import 'src/styles/common/variables/colors';

$light-blue-data-tile-background-color: $danube;

@mixin data-tile-container-like {
  border: 1px solid $data-tile-border-color;
  border-radius: $data-tile-border-radius;
  margin-top: $section-margin-top;
  margin-bottom: $section-margin-bottom;
  background-color: $page-background-color;
}

@mixin data-tile-header-like {
  box-sizing: border-box;
  padding: $data-tile-content-spacing-top $data-tile-content-spacing-side;
  background-color: $data-tile-header-background-color;
  color: $data-tile-header-color;
  font-weight: $font-weight-bold;
  font-size: 1rem;
  &,
  & * {
    text-transform: uppercase;
  }

  &.data-tile-header-light {
    background-color: $light-blue-data-tile-background-color;
  }
}

/**
Sample tag structure:
* div.data-tile-container
  * div.data-tile-row
    * div.data-tile
      * div.data-tile-header
      * div.data-tile-content
    * div.data-tile
      * div.data-tile-header
      * div.data-tile-content
  * div.data-tile-row
    * div.data-table-tile
      * table.data-tile-content
      * ...
 */
div.data-tile-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  @include data-tile-container-like;

  &.data-tile-container-blue {
    background-color: $notice-blue-background-color;
    border-color: $notice-blue-color;
  }
  &.data-tile-container-green {
    background-color: $notice-green-background-color;
    border-color: $notice-green-color;
  }
  &.data-tile-container-yellow {
    background-color: $notice-yellow-background-color;
    border-color: $notice-yellow-color;
  }
  &.data-tile-container-red {
    background-color: $notice-red-background-color;
    border-color: $notice-red-color;
  }

  div.data-tile-row {
    display: flex;
    @include gt-fx-md {
      flex-direction: row;
    }
    @include lt-lg {
      flex-direction: column;
    }
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    & > * {
      width: 100%;
    }

    &:first-of-type {
      @include gt-fx-md {
        & .data-tile:first-of-type > .data-tile-header {
          margin-top: 0;
          border-top-left-radius: $data-tile-border-radius;
        }
        & .data-tile:last-of-type > .data-tile-header {
          margin-top: 0;
          border-top-right-radius: $data-tile-border-radius;
        }
      }
      @include lt-lg {
        & .data-tile:first-of-type > .data-tile-header {
          margin-top: 0;
          border-top-left-radius: $data-tile-border-radius;
          border-top-right-radius: $data-tile-border-radius;
        }
      }
    }
  }

  hr.data-tile-row-separator {
    margin: calc(#{-1 * $data-tile-content-spacing-bottom} + #{$data-tile-content-spacing-top}) $data-tile-content-spacing-side
      $data-tile-content-spacing-top;
    border: 0;
    border-top: 1px solid $data-tile-border-color;
  }

  div.data-tile {
    width: 100%;

    & > div.data-tile-header {
      @include data-tile-header-like;
      width: 100%;
      p {
        margin: 0;
      }
      div {
        padding-right: $data-tile-header-content-spacing-side;
      }
    }

    & > div.data-tile-content {
      border-top: $data-tile-content-spacing-top solid transparent;
      border-left: $data-tile-content-spacing-side solid transparent;
      border-right: $data-tile-content-spacing-side solid transparent;
      border-bottom: $data-tile-content-spacing-bottom solid transparent;
      box-sizing: border-box;
    }
  }
}

div.data-table-container {
  & div.data-table-wrapper {
    @include data-tile-container-like;
    width: 100%;
    overflow-x: auto;

    & > table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;

      & > thead {
        & > tr {
          & > th {
            @include data-tile-header-like;
            text-align: left;
            border-bottom: none;
          }

          & > th.sticky-first:first-of-type {
            border-top-left-radius: $data-tile-border-radius;
            position: sticky;
            left: 0;
            z-index: 1;
            background-color: $dark-blue;
          }

          & > th.sticky-second:nth-of-type(2) {
            position: sticky;
            left: 0;
            z-index: 1;
            background-color: $dark-blue;
          }

          & > th:last-of-type {
            border-top-right-radius: $data-tile-border-radius;
          }
        }
      }

      & > tbody {
        & > tr > td {
          padding: $data-tile-content-spacing-top $data-tile-content-spacing-side;
        }
        & > tr > td.sticky-first:first-of-type {
          border-top-left-radius: $data-tile-border-radius;
          position: sticky;
          left: 0;
          z-index: 1;
          background-color: $white;
        }

        & > tr > td.sticky-second:nth-of-type(2) {
          position: sticky;
          left: 0;
          z-index: 1;
          background-color: $white;
        }

        & > tr:not(:last-child) {
          border-bottom: solid 1px $data-tile-between-rows-border-color;
        }
      }

      th.text-capitalize {
        text-transform: capitalize;
      }
      td.wrap-content {
        white-space: normal;
      }
      th,
      td {
        vertical-align: top;
      }
      td {
        white-space: nowrap;
      }
    }
  }
}

.data-tile-content-column {
  display: flex;
  flex-direction: column;
  & > * {
    &:not(:last-child) {
      margin-bottom: $data-tile-content-spacing-bottom;
    }
  }
}

.data-spaced-column {
  display: flex;
  flex-direction: column;
  & > * {
    &:not(:last-child) {
      margin-bottom: $data-tile-content-spacing-bottom * 2;
    }
  }
}

.data-table-inactive {
  opacity: 0.5;
}
