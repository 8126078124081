//new
$white: #ffffff;
$light-grey-1: #f9f9fb;
$athens-grey: #f7f9fb;
$dark-blue: #33568a;
$dark-grey: #545356;
$periwinkle-grey: #d5deef;
$black: #000000;
$light-grey-2: #e2e2ec;
$light-blue: #dbe7f1;
$solitude: #eaf0fb;
$blue: #4b79bc;
$grey: #8d8c8f;
$alizarine-crimson: #e02020;
$mandys-pink: #f5b8b8;
$la-palma: #119426;

$harp: #ddefe0;
$whisper: #eeeef7;
$catskill-white: #fbfcfd;
$light-whisper: #f7f7fb;
$hawkes-blue: #d6dff0;
$derby: #f9e4c6;

//from old ST
$danube: #6daadc;
$pomegranate: #ee3124;
$error: #f44336;
$early-dawn: #fff9e5;
$jaffa: #f37654;
$mid-gray: #626165;
$emperor: #515151;
$cotton-seed: #bfbcba;
$medium-red-violet: #ad24a9;
$brilliant-rose: #f03cac;
$neon-carrot: #ff922d;
$cosmos: #ffd9d9;
$supernova: #ffcb05;

$pistachio: #90d307;
$chartreuse-yellow: #d9e403;
$catalina-blue: #04247b;
