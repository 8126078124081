stx-surgical-intervention-form {
  // temporary fix, only until forms are fixed
  .stx-form-field {
    margin-right: 24px;
  }
}
.stx-form-field {
  label {
    font-size: 0.875rem;
    color: $dark-grey;

    @media print {
      font-size: 1.25em !important;
      line-height: 1.25 !important;
    }
  }

  div {
    font-size: 1rem;
    color: $black;
    mat-checkbox {
      .mat-checkbox-layout {
        font-size: 1rem;
        color: $black;

        @media print {
          font-size: 1.25rem;
          line-height: 1.25 !important;
        }
      }
    }
  }

  &-wide {
    margin-right: 24px;
    width: 350px;

    label {
      font-size: 0.875rem;
      color: $dark-grey;
    }

    div {
      font-size: 1rem;
      color: $black;

      @media print {
        font-size: 1.25rem;
        line-height: 1.25 !important;
      }
    }

    mat-error {
      font-size: 0.7rem;
    }

    .checkbox-margin-0 {
      mat-checkbox {
        margin: 0;
      }
    }
    mat-checkbox {
      margin: 16px;

      label {
        font-size: 1rem;

        @media print {
          font-size: 1.25rem;
          line-height: 1.25 !important;
        }
      }
    }
  }
}

.readonly-input {
  &-wrapper {
    margin: 7px 0 15px 0;
  }

  div.diagnosis-cleft-section-title {
    background-color: $white;
    margin: 15px 0 0 0;
    padding-left: 0;
  }

  div.clef-column-flex {
    margin: 0;
  }

  div {
    padding: 0;
  }

  input.mat-mdc-input-element:disabled {
    color: $black;
  }
}

.flex-form-container {
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.date-container {
  width: 220px;
}

.date-wide-container {
  width: 285px;
}

.select-wide-container {
  width: 400px;
}

.dob-input .mat-form-field-wrapper {
  width: 100%;
}

.search-container {
  margin: 10px;
  @include gt-md {
    margin: 50px;
  }
}

.form-text {
  font-size: 0.875rem;
  color: $dark-grey;
  margin-bottom: 16px;
}

.form-label {
  margin: 8px 0 8px 0;
  font-size: 0.875rem;
  color: $dark-grey;

  @media print {
    font-size: 1.25rem !important;
    line-height: 1.25 !important;
  }
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

legend {
  margin-bottom: 0.5rem;
  max-width: 100%;
}

mat-panel-title {
  font-weight: 700;
  font-size: 1.1rem;
}

.mat-exp-header {
  color: $black;
}

.st-print-logo {
  margin: 100px 0 0 -2px;
  height: 220px;
}

.checkbox-center {
  align-self: center;
  margin-top: -16px;
}

.width-40-percent {
  @include gt-md {
    width: 40%;
  }
}

.small-input {
  width: 300px;
}

.sub-header {
  text-transform: uppercase;
  color: $black;
  font-size: 0.875rem;
}

.element-text label {
  white-space: normal;
}

.checkbox-header-info {
  font-weight: 400;
  font-size: 0.875rem;
  background-color: $light-blue;
  display: flex;
  padding: 0 0 8px 16px;
  margin-bottom: 16px;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.geo-scope,
.org-scope {
  width: 351px;

  @include lt-md {
    max-width: 250px;
    width: 100%;
  }
  @include lt-sm {
    max-width: 200px;
    width: 100%;
  }

  .mat-checkbox-layout {
    white-space: normal;
    padding-right: 20px;
    .mat-checkbox-label {
      word-break: break-word;
    }
  }
}

.operation-type-radio-button,
.operation-type-checkbox {
  label {
    white-space: normal;
  }
}

stx-orthognathic-treatment {
  .uppy-DashboardAddFiles-info {
    display: none !important;
  }
}

.treatment-tree-container {
  .mat-checkbox-layout {
    .mat-checkbox-label {
      white-space: normal;
    }
  }
}

.calc-result-bar {
  margin-left: 20%;
  margin-bottom: 38px;
  padding: 10px;
  border-radius: 20px;

  @include gt-hd {
    width: 800px;
  }
}

.mat-mdc-select .mat-select-arrow {
  visibility: hidden;
}

.mat-mdc-checkbox.mat-accent {
  .mat-checkbox-inner-container {
    margin: 4px 8px auto 0;
  }
}

.form-control-margin {
  margin-bottom: 1.5em;
}

stx-surgery-stats-report {
  stx-radio-tree-filter {
    stx-radio-group {
      div.mat-radio-label-content {
        font-size: 0.875rem;
        color: $dark-grey;
      }
    }
  }
}
