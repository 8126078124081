@import 'src/styles/common';
@import 'src/styles-new/common';

$form-section-header-spacing-bottom: 0.7em;
$form-section-separator-spacing: $form-section-header-spacing-bottom;
$form-section-separator-border: 2px;
$form-column-max-width: 400px;
$form-row-spacing-between: 0.5em;
$debug-background-color: #c1a0dd;

div.form-row {
  @include layout-column-common-base($form-element-spacing-horizontal, $form-element-spacing-vertical);

  font-size: 0.875rem;
  page-break-inside: avoid;

  & + div.form-row {
    margin-top: $form-row-spacing-between;
  }

  @include debug-colors {
    border: 1px dotted $debug-background-color;
    box-sizing: border-box;
  }

  & > * {
    margin-top: auto;
    margin-bottom: auto;
  }

  &.form-row-valign-top > * {
    margin-top: 0;
    margin-bottom: auto;
  }

  &.form-row-valign-bottom > * {
    margin-top: auto;
    margin-bottom: 0;
  }

  @media print {
    font-size: 1.25rem;
    line-height: 1.25 !important;
  }
}

@include generate-columns('form', $form-element-spacing-horizontal, $form-element-spacing-vertical);

div.indented-form-block {
  padding-left: $form-element-indentation;
}

div.form-column {
  max-width: $form-column-max-width;
}

p.form-section-heading {
  font-size: 1rem;
  font-weight: $font-weight-bold;
  margin-bottom: $form-section-header-spacing-bottom;
  text-transform: uppercase;
  @include debug-colors {
    background-color: lighten($debug-background-color, 0%);
  }

  @media print {
    font-size: 1.25rem;
    line-height: 1.25 !important;
  }
}

hr.form-section-separator {
  margin: $form-section-separator-spacing 0;
  border: 0;
  border-top: $form-section-separator-border solid $data-tile-border-color;
}

p.form-subsection-heading {
  font-weight: $font-weight-bold;
  margin-bottom: $form-section-header-spacing-bottom/2;
  text-transform: uppercase;
  @include debug-colors {
    background-color: lighten($debug-background-color, 3%);
  }

  @media print {
    font-size: 1.5rem;
    line-height: 1.25 !important;
  }
}

.form-subsection-separator {
  margin: $form-section-separator-spacing/2 0;
  border: 0;
  border-top: $form-section-separator-border/2 solid $data-tile-border-color;
}

hr.form-subsection-separator {
  @extend .form-subsection-separator;
}

hr.form-spaced-subsection-separator {
  @extend .form-subsection-separator;
  margin: $form-section-separator-spacing 0;
}
