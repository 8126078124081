.table-container-common {
  box-shadow: 0 0 5px $light-grey-2;
  border-radius: 10px;
  border: 1px solid $light-grey-2;
  overflow: auto;

  &::ng-deep {
    .mat-sort-header-arrow {
      color: $white;
    }
  }

  .mat-mdc-table {
    box-shadow: 0 0 5px $light-grey-2;
    border-radius: 10px;
    border: 1px solid $light-grey-2;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mat-mdc-row:hover {
    background-color: $light-grey-2;
  }

  th {
    padding: 10px;
    text-align: left;
    font-size: 0.875rem;
  }

  td {
    @include gt-md {
      white-space: nowrap;
      vertical-align: top;
      padding-top: 10px;
      padding-left: 10px;
    }
  }

  .sticky-row {
    .mat-mdc-header-cell.mat-mdc-table-sticky {
      border-right: 0;
    }

    .mat-mdc-table-sticky {
      border-bottom: solid 2px rgba(0, 0, 0, 0.12);
    }
  }

  .mat-mdc-cell,
  .mat-mdc-header-cell {
    &.mat-mdc-table-sticky {
      border-right: solid 2px rgba(0, 0, 0, 0.12);

      .no-sticky-border {
        border-right: 0;
      }
    }
  }

  .mat-mdc-footer-cell {
    border-bottom: 0;
  }

  .mat-mdc-table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }

  .mat-mdc-table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }

  .mat-mdc-row:last-child {
    .mat-mdc-cell {
      border-bottom: 0;
    }
  }

  .mat-mdc-table th:first-child {
    border-top-left-radius: 10px;
  }
}
