.rot-0 {
  transform: rotate(0deg);
}

.rot-1 {
  transform: rotate(90deg);
}

.rot-2 {
  transform: rotate(180deg);
}

.rot-3 {
  transform: rotate(270deg);
}

.flip-x {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
