.bold {
  font-family: $font-bold;
}

.medium {
  font-family: $font-medium;
}

.normal {
  font-family: $font-regular;
}

.light {
  font-family: $font-light;
}
