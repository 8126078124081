.full-width {
  width: 100%;
}

.max-full-width {
  max-width: 100%;
}

.full-height {
  height: 100%;
}

.half-full-width {
  width: 50%;
}

.flex-fill {
  margin: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}
