@import 'src/custom-theme';
@import 'src/styles/global/fonts';
@import 'src/styles-new/global';
@import 'src/styles/atomic/index';
@import '~@uppy/core/dist/style.css';
@import '~@uppy/dashboard/dist/style.css';

body.new-ui {
  .csv-icon {
    cursor: pointer;
    vertical-align: middle;

    &__list {
      @extend .csv-icon;
      margin: 20px 0 10px 25px;
      @include gt-md {
        margin: 50px 0 10px 55px;
      }
    }
  }
}

body.old-ui {
  @import 'src/styles/common/index';
  @import 'src/styles/global/common';
  @import 'src/styles/global/buttons';
  @import 'src/styles/global/notifications';
  @import 'src/styles/global/forms';
  @import 'src/styles/global/print';
  @import 'src/styles/global/ui';
  @import 'src/styles/global/tables';
  @import 'src/styles/atomic/transform';
  @import 'src/styles/atomic/index'; // once again, needed for the specifity

  .text-important {
    color: $black;
  }

  .mat-expansion-panel-header:hover:not(.migrated) {
    background-color: $light-blue;
  }

  .mat-expansion-panel-header.mat-expanded:not(.migrated),
  .mat-expansion-panel-header.active:not(.migrated) {
    font-weight: 600;
  }

  mat-divider.mat-divider-margin {
    margin: 0 24px 0 24px;
  }

  mat-divider.mat-form-divider {
    margin: 6px 0 24px 0;
    width: 100%;
  }

  mat-divider.mat-divider-horizontal-margin {
    margin: 24px 0 24px 0;
  }
  .mat-form-field-appearance-outline:hover {
    color: $white;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: $dark-grey;
    }
  }
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: inherit;
  }

  .mat-mdc-dialog-container {
    padding: 0px !important;
  }

  .input-field-auth {
    width: 351px;

    @include lt-md {
      max-width: 250px;
      width: 100%;
    }
    @include lt-sm {
      max-width: 100%;
    }
  }

  .input-field--edit-settings {
    max-width: 351px;
    margin: 0 2rem 0 auto;
  }

  .auth-button {
    height: 43px;
    width: 108px;
    margin-top: 15px !important;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 500px white inset;
  }
  .mat-form-field-suffix {
    margin: auto;
  }

  .mat-sort-header-container {
    justify-content: left;
  }

  .box-container .mat-mdc-cell {
    padding: 10px;
    text-align: left;
  }

  .full-screen-component {
    margin: 0 10px 10px 10px;
    @include gt-md {
      margin: 0 50px 50px 50px;
    }
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: $light-grey-2;
    margin: 8px 0;
  }

  .table-container {
    border-radius: 10px;
    border: 1px solid $light-grey-2;
    overflow: auto;
  }

  .page-section-container {
    padding: 24px;
    border-radius: 8px;
    border: 1px solid $light-grey-2;
    overflow: auto;
    box-shadow: 0 2px 4px 0 $light-grey-2;
    background-color: $white;
  }

  .box-container {
    box-shadow: 0 0 5px $light-grey-2;
    border-radius: 10px;
    border: 1px solid $light-grey-2;
    width: 100%;
    background-color: $white;
    position: relative;
  }

  .page-section {
    padding: 24px;
    border: 1px solid $light-grey-2;
    border-radius: 10px;
    box-shadow: 0 0 5px $light-grey-2;
    background-color: $white;
    color: $black;
  }

  .profile-section {
    padding: 0;
    margin-top: 24px;
  }

  .profile-button {
    width: 100%;
    height: 82px;
    position: relative;
    padding: 0 16px;
    text-align: left;
    background: transparent;
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 $light-grey-2;

    &:hover {
      background-color: $light-blue;
    }

    &:focus {
      outline: 0;
      background-color: $light-blue;
      font-weight: 600;
    }

    &.active {
      outline: 0;
      background-color: $light-blue;
      font-weight: 600;
    }
  }

  .profile-button-label {
    color: $dark-grey;
    font-size: 0.875rem;
    margin: 30px 10px;
  }

  .profile-button-name {
    font-size: 1rem;
    margin: 30px 0;
  }

  .profile-button-profile-section {
    position: absolute;
    left: 40%;
  }

  .profile-button-person {
    position: absolute;
    right: 2%;
  }

  .float-left {
    float: left;
  }

  .page-content {
    h1 {
      margin-top: 60px;
    }
  }

  .header-block {
    margin: 30px 0 50px 0;
  }

  .column-header {
    text-transform: uppercase;
    font-size: 0.875rem;
    color: $black;
    margin-bottom: 15px;
  }

  .column-section {
    @include gt-md {
      min-width: 295px;
    }
  }

  .csv-icon {
    cursor: pointer;
    vertical-align: middle;

    &__list {
      @extend .csv-icon;
      margin: 20px 0 10px 25px;
      @include gt-md {
        margin: 50px 0 10px 55px;
      }
    }
  }

  .no-items-text {
    font-size: 0.875rem;
    width: 300px;
    text-align: center;
  }

  .bordered-table {
    td.mat-mdc-cell,
    th.mat-mdc-header-cell {
      border-left: 1px solid $light-grey-2;
      padding-left: 10px;
    }

    td.mat-mdc-cell:first-child,
    th.mat-mdc-header-cell:first-child {
      border-left: none;
    }
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }

  .half-width-responsive {
    @include gt-md {
      width: 48%;
    }
    @media print {
      width: 48%;
    }
    width: 100%;
  }

  .third-width-responsive {
    @media print {
      width: 33%;
    }
    @include gt-md {
      width: 33%;
    }
    width: 100%;
  }

  .search-header {
    margin-left: 50px;
    margin-top: 62px;
  }

  .calculator-link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $dark-blue;
    font-weight: 600;
    font-size: 1rem;

    span {
      padding-left: 10px;
    }
  }

  .background-green {
    border: 1px solid $la-palma;
    background-color: $harp;
  }

  .background-orange {
    border: 1px solid $neon-carrot;
    background-color: $cosmos;
  }

  .background-red {
    border: 1px solid $pomegranate;
    background-color: $early-dawn;
  }

  div.mat-calendar-arrow {
    display: none;
  }

  .mat-expansion-indicator {
    margin-left: 8px;
  }

  .dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }

  .panel-color {
    background-color: $whisper;
    border-radius: 8px 8px 0 0;
  }

  .active-matching-background {
    background-color: $whisper;
  }

  stx-patient-release-form .stx-form-field-wide {
    width: 250px;
  }
}
