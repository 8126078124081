@import 'src/styles/common/mixins/media-queries';

@mixin layout-column-common-base($horizontal-space, $vertical-space) {
  display: flex;
  box-sizing: border-box;

  & > * {
    flex: 1 1;
    box-sizing: border-box;
  }

  @include gt-md {
    flex-direction: row;

    & > * {
      width: 0;
    }

    & > *:not(:last-child) {
      margin-right: $horizontal-space;
    }
  }

  @include lt-md {
    flex-direction: column;

    & > * {
      flex-basis: auto; // needed for IE11, more: https://makandracards.com/makandra/54644-do-not-use-flex-1-or-flex-basis-0-inside-flex-direction-column-when-you-need-to-support-ie11
    }

    & > *:not(:last-child) {
      margin-bottom: $vertical-space;
    }
  }
}

@mixin generate-columns($prefix, $horizontal-space, $vertical-space) {
  div.#{$prefix}-columns-1-1 {
    @include layout-column-common-base($horizontal-space, $vertical-space);

    @include gt-md {
      & > * {
        flex-basis: calc(50% - #{$horizontal-space});
      }
    }
  }

  div.#{$prefix}-columns-1-2 {
    @include layout-column-common-base($horizontal-space, $vertical-space);

    @include gt-md {
      & > *:nth-child(1) {
        flex-basis: calc(33.3% - #{$horizontal-space});
        flex-grow: 1;
      }

      & > *:nth-child(2) {
        flex-basis: calc(66.6% - #{$horizontal-space});
        flex-grow: 2;
      }
    }
  }

  div.#{$prefix}-columns-2-1 {
    @include layout-column-common-base($horizontal-space, $vertical-space);

    @include gt-md {
      & > *:nth-child(1) {
        flex-basis: calc(66.6% - #{$horizontal-space});
        flex-grow: 2;
      }

      & > *:nth-child(2) {
        flex-basis: calc(33.3% - #{$horizontal-space});
        flex-grow: 1;
      }
    }
  }

  div.#{$prefix}-columns-1-1-1 {
    @include layout-column-common-base($horizontal-space, $vertical-space);

    @include gt-md {
      & > * {
        flex-basis: calc(50% - #{$horizontal-space});
      }
    }
  }

  div.#{$prefix}-columns-1-2-1 {
    @include layout-column-common-base($horizontal-space, $vertical-space);

    @include gt-md {
      & > * {
        flex-basis: calc(25% - #{$horizontal-space});
        flex-grow: 1;
      }

      & > *:nth-child(2) {
        flex-basis: calc(50% - #{$horizontal-space});
        flex-grow: 2;
      }
    }
  }
}
