@import 'src/styles-new/common/variables';

table.mat-mdc-table.sticky-first {
  & > thead,
  & > tbody,
  & > tfoot {
    & > tr {
      height: unset;
    }
    & > tr > th,
    & > tr > td {
      background-color: white;
      &:first-of-type {
        @include gt-md {
          position: sticky;
          left: 0;
          z-index: 1;
        }
      }
    }
  }
}
