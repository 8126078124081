$values: (auto, always, avoid, left, right, initial, inherit);

@each $value in $values {
  .page-break-before-#{$value} {
    page-break-before: #{$value};
  }
}

@each $value in $values {
  .page-break-after-#{$value} {
    page-break-after: #{$value};
  }
}

@each $value in $values {
  .page-break-inside-#{$value} {
    page-break-inside: #{$value};
  }
}
