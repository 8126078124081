legend {
  padding-left: 0;
  padding-right: 0;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}
