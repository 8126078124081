.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

.display-table {
  display: table;
}

.display-inline-block {
  display: inline-block;
}

.display-none {
  display: none;
}

.display-grid {
  display: grid;
}
