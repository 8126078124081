@import 'src/styles-new/common/variables';

@font-face {
  font-family: $custom-font-family;
  font-style: normal;
  font-weight: $font-weight-light;
  src: url('../../assets/fonts/NotoSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: $custom-font-family;
  font-style: normal;
  font-weight: $font-weight-medium;
  src: url('../../assets/fonts/NotoSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: $custom-font-family;
  font-style: normal;
  font-weight: $font-weight-regular;
  src: url('../../assets/fonts/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: $custom-font-family;
  font-style: normal;
  font-weight: $font-weight-bold;
  src: url('../../assets/fonts/NotoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: $custom-font-family;
  font-style: italic;
  font-weight: $font-weight-regular;
  src: url('../../assets/fonts/NotoSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: $custom-font-family;
  font-style: italic;
  font-weight: $font-weight-bold;
  src: url('../../assets/fonts/NotoSans-BoldItalic.ttf') format('truetype');
}
