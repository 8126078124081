$spaceAmounts: (0, 2, 4, 5, 8, 10, 15, 20, 24, 25, 30, 32, 35, 40, 45, 50, 75, 85, 95, 100);
$sides: (top, bottom, left, right);

@each $space in $spaceAmounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px;
    }
  }
}

@each $space in $spaceAmounts {
  .m-#{$space} {
    margin: #{$space}px;
  }
}

.m-l-auto {
  margin-left: auto;
}
