@import '../common';

$form-label-color: $dark-grey;
$form-element-group-spacing-horizontal: $form-element-spacing-horizontal;
$form-element-group-spacing-vertical: $form-element-spacing-vertical;

.form-control-margin {
  margin-bottom: 1.5em;
}
.form-element-label {
  display: block;
  font-size: 0.875rem;
  color: $form-label-color;
  margin-bottom: $form-element-group-spacing-vertical;

  @media print {
    font-size: 1.25em !important;
    line-height: 1.25 !important;
  }
}

.form-element-secondary-label,
.form-element-label small {
  font-style: italic;
  font-size: 0.75em;
  color: $form-label-color;

  @media print {
    font-size: 1.25em !important;
    line-height: 1.25 !important;
  }
}

// radio/checkbox groups:

@mixin form-element-group {
  display: flex;
  & > *:not(:last-child) {
    margin-right: $form-element-group-spacing-horizontal;
    margin-bottom: $form-element-group-spacing-vertical;
  }
}

.form-element-group-column {
  @include form-element-group;
  flex-direction: column;
}

.form-element-group-row {
  @include form-element-group;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    margin-right: $form-element-group-spacing-horizontal;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.form-element-group-grid-2cols {
  @include form-element-group;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    width: calc(50% - #{$form-element-group-spacing-horizontal});
  }
  &-wrap {
    @extend .form-element-group-grid-2cols;
    @include lt-sm {
      flex-direction: column;
      & > * {
        width: 100%;
      }
    }
  }
}

.form-element-group-grid-3cols {
  @include form-element-group;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    width: calc(33.3% - #{$form-element-group-spacing-horizontal});
  }
}

.form-element-group-grid-4cols {
  @include form-element-group;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    width: calc(25% - #{$form-element-group-spacing-horizontal});
  }
}

.force-vt-spacing {
  margin-bottom: $form-element-group-spacing-vertical !important;
}

.force-hz-spacing {
  margin-right: $form-element-group-spacing-horizontal !important;
}
