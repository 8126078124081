.status-bar {
  color: $black;
  border-radius: 8px;
  box-shadow: none;

  &.status-bar-green {
    border: 1px solid $la-palma;
    background-color: $harp;
  }

  &.status-bar-yellow {
    border: 1px solid $supernova;
    background-color: $early-dawn;
  }

  &.status-bar-red {
    border: 1px solid $alizarine-crimson;
    background-color: $cosmos;
  }

  .status-container {
    mat-icon {
      color: $dark-blue;
      margin-right: 4px;
      &.status-icon-red {
        color: $pomegranate;
      }

      &.status-icon-grey {
        color: $grey;
      }
    }
  }

  .add-treatment-container {
    margin-top: 32px;
    a {
      display: flex;
      margin-top: 16px;
    }
  }
}
