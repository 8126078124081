@mixin generate-header($tag, $font-size) {
  #{$tag},
  .text-#{$tag} {
    font-size: $font-size;
    line-height: $font-size;
    text-transform: uppercase;
    margin-block-end: $font-size;
    margin-block-start: $font-size;
  }
}

@include generate-header('h1', 1.75em);
@include generate-header('h2', 1.5em);
@include generate-header('h3', 1.25em);

strong,
.text-strong {
  font-weight: 700;
}
small,
.text-small {
  font-size: 0.75em;
  font-weight: 200;
}
