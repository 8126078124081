mat-paginator.mat-mdc-paginator {
  background: unset;
}

.uppy-image .uppy-DashboardTabs {
  background-image: none;
}

.uppy-image .uppy-Dashboard-dropFilesTitle > span {
  text-transform: uppercase;
  margin-top: 0;
}

.mat-mdc-card .mat-divider-horizontal {
  position: unset !important;
}

.tooltip-icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
  margin-left: 8px;
}

.dark-grey-background {
  background-color: $dark-grey !important;
}

mat-divider.divider-margin {
  margin: 0 59px 0 59px;
}

.draft-status {
  font-size: 1rem;
  mat-icon {
    vertical-align: middle;
    font-size: 20px;
    height: 20px;
    width: 20px;
    color: $dark-blue;
  }
  span {
    vertical-align: middle;
  }
}

.treatment-status {
  font-size: 1rem;
  mat-icon {
    font-size: 22px;
    height: 22px;
    width: 22px;
    color: $dark-blue;
  }
}

.completed-icon {
  font-size: 32px;
  width: 32px;
  height: 32px;
  color: $blue;
  margin-right: 16px;
}

.tool-container {
  margin: 62px 66px;
}

.completed-block {
  width: 525px;
  height: 65px;
  border-radius: 8px;
  padding: 16px;
  color: $black;
}

.yellow-container {
  border: 1px solid $supernova;
  background-color: $early-dawn;
  border-radius: 8px;
  padding: 24px;
}

.status-icon {
  font-size: 22px;
  height: 22px;
  width: 22px;
}
