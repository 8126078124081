.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.break-word {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.white-space-normal {
  white-space: normal !important;
}
