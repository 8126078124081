@mixin a-margin {
  margin: 5px 0;
}

@mixin p-details {
  margin: 5px 0;
  color: $black;
}

@mixin standard-page {
  display: block;
  @include gt-md {
    padding: 50px;
  }
  padding: 10px;
  max-width: 1300px;
  margin: 0 auto;
}

@mixin cleared-edit-form {
  @media print {
    .stx-form-field {
      input.mat-mdc-input-element {
        display: none;
      }
      .mat-select-value {
        display: none;
      }
      textarea.mat-mdc-input-element {
        display: none;
      }
      &-wide {
        div {
          input.mat-mdc-input-element {
            display: none;
          }
          .mat-select-value {
            display: none;
          }
        }
      }
    }
  }
}
