.stx-mat-error-align {
  display: flex !important;
  align-items: start !important;
  font-size: 0.65rem !important;
  overflow-x: clip !important;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 0.5rem !important;
}
