.static-text-container {
  p {
    &:not(:last-child) {
      margin-bottom: $data-tile-content-spacing-bottom * 2;
    }
  }

  li,
  p {
    line-height: $base-line-size * 1.5;
  }

  ul {
    margin-bottom: $data-tile-content-spacing-bottom;
  }
}

.separated-item {
  display: flex;
  align-self: center;

  &:after {
    content: '|';
    padding: 0 0.5rem;
    color: $grey;
  }
  &:last-child {
    &:after {
      content: '';
    }
  }

  &-margin {
    padding: 0.4em 0;
  }
}

.prefix-label {
  padding-top: 0.1rem;
  &:after {
    content: ' — ';
    margin-right: 0.35rem;
  }
}

.resource-spaced-column {
  & > * {
    &:not(:last-child) {
      margin-bottom: $data-tile-content-spacing-bottom;
    }
  }
}

.dashboard-announcement {
  .resource-spaced-column {
    margin-bottom: 1.5rem;
  }
  i.material-icons {
    font-size: #{$base-font-size}px;
    vertical-align: top;
  }
  .resource-translations {
    max-width: 600px;
    display: flex;
    flex-flow: row wrap;
  }
  p,
  p + ul > li {
    line-height: $base-line-size * 1.5;
  }
}
