@use '@angular/material' as mat;
@import 'styles/common/index';
@import 'styles-new/common';

$blue-primary: (
  50: $white,
  100: $light-grey-1,
  200: $light-grey-1,
  300: $light-grey-1,
  400: $light-grey-1,
  500: $dark-blue,
  600: $blue,
  700: $blue,
  800: $dark-grey,
  900: $black,
  A100: $blue,
  A200: $light-grey-2,
  A400: $grey,
  A700: $grey,
  contrast: (
    50: $dark-grey,
    100: $dark-grey,
    200: $dark-grey,
    300: $dark-grey,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $black,
    A200: $black,
    A400: $white,
    A700: $white
  )
);

$blue-accent: (
  50: $white,
  100: $light-grey-2,
  200: $light-grey-2,
  300: $light-blue,
  400: $light-blue,
  500: $blue,
  600: $blue,
  700: $blue,
  800: $grey,
  900: $black,
  A100: $dark-grey,
  A200: $dark-grey,
  A400: $dark-grey,
  A700: $dark-grey,
  contrast: (
    50: $dark-grey,
    100: $dark-grey,
    200: $dark-grey,
    300: $dark-grey,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $black,
    A200: $black,
    A400: $white,
    A700: $white
  )
);

$custom-theme-primary: mat.m2-define-palette($blue-primary);
$custom-theme-accent: mat.m2-define-palette($blue-accent, A200, A100, A400);
$custom-theme-warn: mat.m2-define-palette(mat.$m2-red-palette);

$typography: mat.m2-define-typography-config(
  $font-family: $base-font-family,
  $body-2: mat.m2-define-typography-level(1em, normal, normal) /* sets `line-height` for radio buttons/checkboxes */
);

$custom-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $custom-theme-primary,
      accent: $custom-theme-accent,
      warn: $custom-theme-warn
    ),
    typography: $typography,
    density: 0
  )
);

@include mat.typography-hierarchy($custom-theme);

@include mat.all-component-themes($custom-theme);
@include mat.core();

@include mat.all-component-typographies($typography);
@include mat.core();
@include mat.all-component-themes($custom-theme);

@mixin components-theme($custom-theme) {
  $primary: map-get($custom-theme, primary);
  $accent: map-get($custom-theme, accent);

  // buttons
  .mat-mdc-raised-button,
  .mat-mdc-outlined-button {
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase;
    height: 43px;
  }
  .mat-mdc-raised-button:disabled {
    background-color: mat.m2-get-color-from-palette($accent, 800) !important;
    color: $white !important;
  }
  .mat-mdc-outlined-button:disabled {
    color: mat.m2-get-color-from-palette($accent, 800);
  }
  .mat-mdc-outlined-button:not([disabled]) {
    border-color: mat.m2-get-color-from-palette($primary, 500);
  }
  .mat-mdc-raised-button:hover {
    background-color: mat.m2-get-color-from-palette($accent, 500);
    opacity: 0.9;
  }
  .mat-mdc-outlined-button:hover {
    background-color: mat.m2-get-color-from-palette($accent, 300);
    opacity: 0.9;
  }

  .mat-mdc-card-content {
    font-size: 1rem;
  }

  // text
  button {
    font-size: 0.875rem;
  }
  h1 {
    color: $black;
    font-size: 1.75rem;
    font-weight: 700;
  }
  h2 {
    font-size: 1.5rem;
    color: $black;
    font-weight: 400;
  }
  h3 {
    color: $black;
    font-size: 1.375rem;
    font-weight: 600;
  }
  h4 {
    color: $black;
    font-size: 1.125rem;
    font-weight: 600;
  }
  a {
    color: $dark-blue;
    font-size: 1rem;
    text-decoration: none;
    cursor: pointer;
    font-family: $font-medium;
  }
}

@include components-theme($custom-theme);
