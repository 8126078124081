@import 'src/styles-new/common';

$data-section-spacing-bottom: 1.5em;
$data-section-header-spacing-bottom: $data-section-spacing-bottom / 2;
$data-section-separator-spacing: $data-section-header-spacing-bottom;
$data-section-separator-border: 2px;
$debug-background-color: #bddcff;

div.data-section {
  margin-bottom: $data-section-spacing-bottom;

  @include debug-colors {
    background-color: lighten($debug-background-color, 20%);
  }

  div.data-section-heading {
    width: 100%;
    font-weight: $font-weight-bold;
    margin-bottom: $data-section-header-spacing-bottom;

    @include debug-colors {
      background-color: lighten($debug-background-color, 0%);
    }
  }

  div.data-subsection-heading {
    width: 100%;
    font-weight: $font-weight-regular;
    @include debug-colors {
      background-color: lighten($debug-background-color, 3%);
    }
  }
}

.data-section-separator {
  margin: calc(#{-1 * $data-section-spacing-bottom} + #{$data-section-separator-spacing}) 0 $data-section-separator-spacing;
  border: 0;
  border-top: $data-section-separator-border solid $data-tile-border-color;
}

hr.data-section-separator {
  @extend .data-section-separator;
}

hr.data-subsection-separator {
  margin: $data-section-separator-spacing/2 0;
  border: 0;
  border-top: $data-section-separator-border/2 solid lighten($data-tile-border-color, 7%);
}

hr.data-thin-section-separator {
  @extend .data-section-separator;
  border-top-width: $data-section-separator-border / 2;
  &-spaced {
    @extend .data-thin-section-separator;
    margin-top: $data-section-separator-spacing * 2.5;
    margin-bottom: $data-section-separator-spacing * 2.5;
  }
}
