$spaceAmounts: (0, 2, 5, 10, 15, 20, 24, 25, 30, 32, 35, 40, 45, 50, 75, 100);
$sides: (top, bottom, left, right);

@each $space in $spaceAmounts {
  @each $side in $sides {
    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@each $space in $spaceAmounts {
  .p-#{$space} {
    padding: #{$space}px !important;
  }
}
