$font-sizes: (10, 12, 13, 14, 15, 16, 18, 20, 22, 24, 25, 30, 32, 35, 40, 50);

/*
    Font size classes:
    .fs-10 - font-size: 0.625rem (10px)
    .fs-13 - font-size 0.8125rem (13px)
*/
@each $font-size in $font-sizes {
  .fs-#{$font-size} {
    font-size: #{$font-size / $base-font-size}rem;
  }
}
