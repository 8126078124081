@import 'src/styles-new/common/variables';
@import 'src/styles-new/global/form-sections';

hr.vertical-separator {
  margin: 0 $form-section-separator-spacing;
  border: 0;
  border-right: $form-section-separator-border/2 solid $data-tile-border-color;
  width: 0;
  flex-basis: 0%;
}

.hide-gt-md {
  @include gt-md {
    display: none;
  }
}
